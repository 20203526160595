@use "../../global" as global;

/* ----------------------------
* ページャーエリア
*---------------------------- */

$pc-height: 50px;
$pc-width-a: 50px;
$pc-width-b: 50px;
$pc-icon-length: 15px;
$sp-height: 30px;
$sp-width-a: 30px;
$sp-width-b: 30px;
$sp-icon-length: 10px;

.c-pager {
  display: flex;
  justify-content: center;

  @include global.media(sp) {
    justify-content: center;
  }

  /* ----------------------------
  * ページパーツ
  *---------------------------- */

  &__page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: $pc-width-a;
    height: $pc-height;
    margin: 0 3px;
    cursor: pointer;
    border: global.$color-bg-gray 1px solid;
    border-radius: 10%;
    transition: 0.3s;

    &--current {
      color: global.$color-white;
      pointer-events: none;
      cursor: default;
      background-color: global.$color-button-bg-blue;
    }

    &:hover {
      color: global.$color-white;
      background-color: global.$color-button-bg-blue;
      border: none;
    }

    @include global.media(sp) {
      width: $sp-width-a;
      height: $sp-height;
      margin: 0 5px;
    }
  }

  /* ----------------------------
  * 前のページボタン
  *---------------------------- */

  &__prev {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: $pc-width-a;

    // 有効時
    height: $pc-height;
    margin-right: 30px;
    cursor: pointer;
    background-color: global.$color-white;
    border: 1px solid global.$color-bg-gray;
    border-radius: 10%;
    transition: 0.3s;

    &:hover {
      background-color: global.$color-button-bg-blue;

      &::after {
        background-image: url("../images/pagination-prev-white.png");
      }
    }

    @include global.media(sp) {
      width: $sp-width-a;
      height: $sp-height;
      margin-right: 4px;
    }

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: $pc-icon-length;
      height: $pc-icon-length;
      content: "";
      background-image: url("../images/pagination-prev-blue.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      image-rendering: pixelated;
      transform: translate(-50%, -50%);

      @include global.media(sp) {
        width: $sp-icon-length;
        height: $sp-icon-length;
      }
    }

    // 無効時

    &--disabled {
      color: rgba(global.$color-button-bg-blue, 0.25);
      pointer-events: none;
      cursor: default;
      background-color: global.$color-bg-gray;
      border: none;

      &:hover,
      &:focus,
      &:active {
        background-color: global.$color-white;
      }
    }
  }

  /* ----------------------------
  * 次のページボタン
  *---------------------------- */

  &__next {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: $pc-width-a;

    // 有効時
    height: $pc-height;
    margin-left: 30px;
    cursor: pointer;
    background-color: global.$color-white;
    border: 1px solid global.$color-bg-gray;
    border-radius: 10%;
    transition: 0.3s;

    &:hover {
      background-color: global.$color-button-bg-blue;
      border: none;

      &::after {
        background-image: url("../images/pagination-next-white.png");
      }
    }

    @include global.media(sp) {
      width: $sp-width-a;
      height: $sp-height;
      margin-left: 4px;
    }

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: $pc-icon-length;
      height: $pc-icon-length;
      content: "";
      background-image: url("../images/pagination-next-blue.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      transform: translate(-50%, -50%);

      @include global.media(sp) {
        width: $sp-icon-length;
        height: $sp-icon-length;
      }
    }

    // 無効時

    &--disabled {
      color: rgba(global.$color-button-bg-blue, 0.25);
      pointer-events: none;
      cursor: default;
      background-color: global.$color-bg-gray;
      border: none;

      &:hover {
        background-color: global.$color-white;
      }
    }
  }

  &__next-image {
    width: 15px;
    height: 15px;
  }

  /* ----------------------------
  * 最初へボタン
  *---------------------------- */

  &__first {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    // 有効時
    width: $pc-width-b;
    height: $pc-height;
    margin-right: 10px;
    cursor: pointer;
    background-color: global.$color-white;
    border: 1px solid global.$color-bg-gray;
    border-radius: 10%;
    transition: 0.3s;

    &:hover {
      background-color: global.$color-button-bg-blue;

      &::after {
        background-image: url("../images/pagination-first-white.png");
      }
    }

    // 無効時

    &--disabled {
      color: rgba(global.$color-button-bg-blue, 0.25);
      pointer-events: none;
      cursor: default;
      background-color: global.$color-bg-gray;
      border: none;

      &:hover {
        background-color: global.$color-white;
      }
    }

    @include global.media(sp) {
      width: $sp-width-b;
      height: $sp-height;
      margin-right: 5px;
    }

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: $pc-icon-length;
      content: "";
      background-image: url("../images/pagination-first-blue.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      transform: translate(-50%, -50%);

      @include global.media(sp) {
        width: $sp-icon-length;
        height: $sp-icon-length;
      }
    }
  }

  /* ----------------------------
  * 最後へボタン
  *---------------------------- */

  &__last {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    // 有効時
    width: $pc-width-b;
    height: $pc-height;
    margin-left: 10px;
    cursor: pointer;
    background-color: global.$color-white;
    border: 1px solid global.$color-bg-gray;
    border-radius: 10%;
    transition: 0.3s;

    &:hover {
      background-color: global.$color-button-bg-blue;

      &::after {
        background-image: url("../images/pagination-last-white.png");
      }
    }

    // 無効時

    &--disabled {
      color: rgba(global.$color-button-bg-blue, 0.25);
      pointer-events: none;
      cursor: default;
      background-color: global.$color-bg-gray;
      border: none;

      &:hover,
      &:focus,
      &:active {
        background-color: global.$color-white;
      }
    }

    @include global.media(sp) {
      width: $sp-width-b;
      height: $sp-height;
      margin-left: 5px;
    }

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: $pc-icon-length;
      content: "";
      background-image: url("../images/pagination-last-blue.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      transform: translate(-50%, -50%);

      @include global.media(sp) {
        height: $sp-icon-length;
      }
    }
  }

  &__text {
    @include global.font-settings(16, 12, false, false, 500, false);
  }

  &__edge-text {
    @include global.font-settings(16, 10, false, false, 500, false);
  }
}
