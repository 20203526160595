@use "../../global" as global;

.p-common-feature {
  display: flex;
  justify-content: center;
  max-width: 1100px;
  padding-top: 80px;
  margin-right: auto;
  margin-left: auto;

  &__proposal-values {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 15px 0 30px;

    @include global.media(sp) {
      padding: 0 15px;
    }
  }

  &__proposal-desc-wrapper {
    position: relative;
    width: 100%;
    padding: 36px 16px;
    margin-bottom: 20px;
    background-color: global.$color-white;
    border-radius: 10px;
  }

  &__proposal-values-title-wrapper {
    position: absolute;
    top: -15px;
    left: -15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    height: 42px;
    color: global.$color-white;
    background-color: global.$color-border-blue;
    border-radius: 10px;

    @include global.media(sp) {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__proposal-values-title {
    @include global.font-settings(22, 18, 1.3, 1.3, 500, 50);
  }

  &__proposal-values-text-wrapper {
    margin-bottom: 20px;
  }

  &__proposal-values-text {
    @include global.font-settings(18, 17, 1.3, 1.3, 400, false);
  }

  &__proposal-values-list {
    margin-left: 2em;

    @include global.media(sp) {
      margin: 0;
    }
  }

  &__proposal-values-item {
    @include global.font-settings(28, 22, 1.3, 1.3, 500, 50);

    margin-bottom: 10px;
    color: global.$color-border-blue;

    @include global.media(sp) {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__figures {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 45px;

    @include global.media(tb) {
      padding: 0;
    }

    @include global.media(sp) {
      flex-direction: column;
    }
  }

  &__figures-title-wrapper {
    margin-bottom: 20px;
  }

  &__figures-title {
    color: global.$color-border-blue;

    @include global.font-settings(18, 16, 1.3, 1.3, 500, false);
  }

  &__figures-text-wrapper {
    border: global.$color-border-blue solid 3px;
  }

  &__figures-left-contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35%;

    @include global.media(tb) {
      width: 40%;
    }

    @include global.media(sp) {
      width: 100%;
    }
  }

  &__figures-right-contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35%;

    @include global.media(tb) {
      width: 40%;
    }

    & .p-common-feature__figures-text-wrapper {
      background-color: global.$color-border-blue;
      border-radius: 10px;
    }

    & .p-common-feature__figures-text {
      color: global.$color-white;
    }

    @include global.media(sp) {
      width: 100%;
    }
  }

  &__figures-text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 44px;
    margin-bottom: 10px;
  }

  &__figures-text {
    @include global.font-settings(18, 16, 1.3, 1.3, 500, false);
  }

  &__figures-points-wrapper {
    width: 100%;
  }

  &__figures-arrow-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;

    @include global.media(tb) {
      width: 10%;
    }

    @include global.media(sp) {
      width: 100%;
      margin: 15px 0;
    }
  }

  &__figures-arrow {
    width: 0;
    height: 0;
    border-color: transparent transparent transparent global.$color-arrow-gray;
    border-style: solid;
    border-width: 50px 0 50px 50px;

    @include global.media(sp) {
      border-color: global.$color-arrow-gray transparent transparent;
      border-width: 50px 50px 0;
    }
  }
}
