@use "../../global" as global;

.p-service__cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: auto;
  padding: 0 15px;
  margin-bottom: -41px;

  @include global.media(tb) {
    padding: 0;
    margin-bottom: -15px;
  }
}

.p-top-feature__cards {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  padding: 0 235px;
  margin-bottom: 40px;

  $col_num: 3;
  $col_num-sp: 1;
  $margin-x: 80px;
  $margin-y: 30px;

  @include global.cards($col_num, $margin-x, $margin-y);

  @include global.media(tb) {
    padding: 0;
  }
}

.p-top-white-paper__cards {
  display: flex;
  flex-wrap: wrap;
  max-width: 1100px;
  height: auto;
  margin-right: auto;
  margin-bottom: 50px;
  margin-left: auto;

  $col_num: 3;
  $col_num-sp: 1;
  $margin-x: 32px;
  $margin-y: 30px;

  @include global.cards($col_num, $margin-x, $margin-y);

  @include global.media(tb) {
    padding: 0;
  }
}

.p-common-problem__cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  height: auto;
  padding: 0 15px;
  margin-right: auto;
  margin-bottom: 40px;
  margin-left: auto;

  $col_num: 3;
  $col_num-sp: 1;
  $margin-x: 30px;
  $margin-y: 30px;

  @include global.cards($col_num, $margin-x, $margin-y);
}

.p-features-output__cards {
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
  height: auto;
  margin-right: auto;
  margin-bottom: -40px;
  margin-left: auto;

  $col_num: 3;
  $col_num-sp: 1;
  $margin-x: 32px;
  $margin-y: 40px;

  @include global.cards($col_num, $margin-x, $margin-y);

  @include global.media(tb) {
    padding: 0;
  }
}

.p-post__cards {
  display: flex;
  flex-wrap: wrap;
  max-width: 1240px;
  height: auto;
  margin-right: auto;
  margin-bottom: 25px;
  margin-left: auto;

  $col_num: 3;
  $col_num-sp: 1;
  $margin-x: 35px;
  $margin-y: 35px;

  @include global.cards($col_num, $margin-x, $margin-y);

  @include global.media(tb) {
    padding: 0;
    margin-bottom: 0;
  }
}

.p-common-service-link__cards {
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
  height: auto;
  margin-right: auto;
  margin-bottom: 50px;
  margin-left: auto;

  $col_num: 3;
  $col_num-sp: 1;
  $margin-x: 15px;
  $margin-y: 15px;

  @include global.cards($col_num, $margin-x, $margin-y);

  @include global.media(tb) {
    padding: 0;
    margin-bottom: 0;
  }
}

.p-service-link {

  &__lead-wrapper {
    width: 100%;
    margin-bottom: 45px;

    @include global.media(sp) {
      margin-top: 30px;
    }
  }

  &__lead {
    text-align: center;

    @include global.font-settings(28, 18, 1.2, 1.2, 600, false);

    @include global.media(sp) {
      text-align: start;
    }
  }
}

.p-learning-proposal__cards {
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
  height: auto;
  margin-right: auto;
  margin-left: auto;

  $col_num: 2;
  $col_num-sp: 1;
  $margin-x: 50px;
  $margin-y: 30px;

  @include global.cards($col_num, $margin-x, $margin-y);

  @include global.media(tb) {
    padding: 0;
    margin-bottom: 15px;
  }
}

.p-features-service-link__cards {
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
  height: auto;
  margin-right: auto;
  margin-bottom: 50px;
  margin-left: auto;

  $col_num: 2;
  $col_num-sp: 1;
  $margin-x: 15px;
  $margin-y: 15px;

  @include global.cards($col_num, $margin-x, $margin-y);

  // @include global.media(tb) {
  //   padding: 0;
  //   margin-bottom: 0;
  // }
}

.p-service-link__cards {
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
  height: auto;
  margin-right: auto;
  margin-bottom: 50px;
  margin-left: auto;

  $col_num: 2;
  $col_num-sp: 1;
  $margin-x: 15px;
  $margin-y: 15px;

  @include global.cards($col_num, $margin-x, $margin-y);
}
