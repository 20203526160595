@use "../../global" as global;

/* ----------------------------
* 共通記事カード
*---------------------------- */

.c-card-post {

  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 280px;
    overflow: hidden;
    background-color: global.$color-bg-light-gray;

    &:hover {

      & .c-card-post__image {
        transform: scale(1.1);
      }

      & .c-card-post__title {
        background-position: bottom left;
        background-size: 100% 1px;
      }
    }

    &--bg-white {
      background-color: global.$color-white;
      box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 25%);
    }
  }

  /* ----------------------------
  * カードイメージ
  *---------------------------- */

  &__image-wrapper {
    position: relative;
    width: 100%;
    aspect-ratio: calc(16 / 9);
    overflow: hidden;
    border-radius: 3px;
    transform: translateZ(0);
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: 0.3s ease-out;
  }

  &__title-on-image-wrapper {
    position: absolute;
    top: 50%;
    left: 0%;
    width: 100%;
    transform: translateY(-50%);
  }

  &__bg-gray {
    width: 100%;
    padding: 20px 15px;
    background-color: global.$color-title-bg-light-gray;
  }

  &__title-on-image {
    color: global.$color-font-black;
    text-align: center;

    @include global.font-settings(16, 16, 1.3, 1.3, 600, false);
    @include global.display-lines(3);
  }

  &__category-wrapper-on-image-wrapper {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  /* ----------------------------
  * カード情報
  *---------------------------- */

  &__info {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 15px;
  }

  &__category-wrapper {
    margin-bottom: 10px;
  }

  &__category {
    @include global.font-settings(16, 14, 1.2, 1.2, 500, false);

    color: global.$color-category-font-gray;
  }

  &__term-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    @include global.font-settings(14, 14, 1.2, 1.2, 400, false);
  }

  &__term-categories {
    display: flex;
    flex-wrap: wrap;
    gap: 0.4rem;
    margin-bottom: 0.5rem;
  }

  &__term-category {
    display: block;
    padding: 3px 0.6rem;
    background-color: global.$color-white;
  }

  &__term-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.4rem;
  }

  &__term-tag {
    display: block;
    padding: 3px 0.6rem;
    background-color: global.$color-white;
    border-radius: 50px;
  }

  &__title-wrapper {
    margin-bottom: 10px;
  }

  &__title {
    @include global.font-settings(22, 18, 1.5, 1.5, 500, 50);

    display: inline;
    word-break: break-all;
    background-image: linear-gradient(global.$color-font-black, global.$color-font-black);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 0 1px;
    transition: background-size 0.3s ease-out;

    &--events {
      @include global.font-settings(16, 14, 1.5, 1.5, 500, false);
    }

    &--white-papers {
      @include global.font-settings(16, 14, 1.5, 1.5, 500, false);
    }
  }

  &__text-wrapper {
  }

  &__text {
    @include global.font-settings(17, 15, 1.5, 1.5, 400, 30);
    @include global.display-lines(2);
  }

  &__date-wrapper {
    padding-left: 1.5em;
  }

  &__date {
    @include global.font-settings(13, 12, 1.2, 1.2, 500, 50);

    color: global.$color-date-gray;

    &--events {
      position: relative;

      &::before {
        position: absolute;
        top: 50%;
        left: -1.25em;
        width: 1em;
        height: 1em;
        content: "";
        background-image: url("../images/calendar.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transform: translate(-50%, -50%);
      }
    }
  }
}
