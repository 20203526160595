@use "../../global" as global;

.p-common-works {

  &__image-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 60px;

    @include global.media(sp) {
      width: 100%;
      margin-bottom: 30px;
      overflow-x: scroll;
    }
  }

  &__image {
    @include global.media(sp) {
      width: 600px;
      max-width: none;
    }
  }

  &__works-num-container {
    display: flex;
    gap: 5rem;
    justify-content: center;
    margin-top: 60px;
    margin-bottom: 30px;

    @include global.media(sp) {
      flex-wrap: wrap;
    }
  }

  &__company-wrapper,
  &__project-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__num {

    &--bold {
      @include global.font-settings(80, 48, 1.5, 1.5, 700, 50);
    }
  }

  &__suffix {
    @include global.font-settings(10, 10, 1, 1, 400, 50);
  }
}
