@use "../../global" as global;

/* ----------------------------
* カルーセルカード
*---------------------------- */

.c-card-climate-change {

  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 280px;
    margin-right: auto;
    margin-left: auto;
    overflow: hidden;

    &:hover {

      & .c-card-climate-change__image {
        transform: scale(1.1);
      }

      & .c-card-climate-change__title {
        background-position: bottom left;
        background-size: 100% 1px;
      }
    }
  }

  /* ----------------------------
  * カードイメージ
  *---------------------------- */

  &__image-wrapper {
    position: relative;
    width: 100%;
    aspect-ratio: calc(16 / 9);
    margin-bottom: 0;
    margin-bottom: 15px;
    overflow: hidden;
    border-radius: 3px;
    transform: translateZ(0);
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: 0.3s ease-out;
  }

  &__title-on-image-wrapper {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translateY(-50%);
  }

  &__bg-gray {
    width: 100%;
    padding: 20px 15px;
    background-color: global.$color-title-bg-gray;
  }

  &__title-on-image {
    color: global.$color-white;
    text-align: center;

    @include global.font-settings(13, 13, 1.5, 1.5, 600, false);
    @include global.display-lines(3);
  }

  &__category-on-image-wrapper {
    position: absolute;
    top: 5px;
    left: 5px;
  }

  /* ----------------------------
  * カード情報
  *---------------------------- */

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__title-wrapper {
    height: 44px;
    margin-right: auto;
    margin-bottom: 6px;
  }

  &__title {
    @include global.font-settings(13, 13, 1.5, 1.5, 600, 50);
    @include global.display-lines(3);

    display: inline;
    background-image: linear-gradient(global.$color-font-black, global.$color-font-black);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 0 1px;
    transition: background-size 0.3s ease-out;
  }
}
