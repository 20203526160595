@use "../global" as global;

.l-footer-custom {
  width: 100%;
  min-width: 350px;
  background-color: global.$color-sub-fv-bg-blue;

  &__inner {
    @include global.inner(global.$width-base);

    padding-top: 60px;
  }
}
