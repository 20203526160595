@use "../../global" as global;

.p-features-lead {
  display: flex;
  gap: 3.5rem;

  @include global.media(sp) {
    flex-direction: column;
  }

  &__heading-wrapper {
    width: 400px;

    @include global.media(sp) {
      width: 100%;
    }
  }

  &__heading {
    @include global.font-settings(26, 20, 2.5, 2.5, 500, 50);

    text-decoration: underline dashed;
    text-underline-offset: 10px;

    @include global.media(sp) {
      text-align: center;
    }
  }

  &__text-wrapper {
    width: calc(100% - 400px - 3.5rem);

    @include global.media(sp) {
      width: 100%;
    }
  }

  &__text {
    @include global.font-settings(18, 16, 1.8, 1.8, 400, 50);

    margin-bottom: 1.5rem;
  }
}
