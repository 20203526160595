@use "../../global" as global;

.p-fv {

  &__title-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-right: auto;
    margin-bottom: 60px;
    margin-left: 100px;
  }

  &__title {
    font-family: global.$font-base;
    font-size: global.rem(60);
    font-weight: 100;
  }

  &__title-point {
    color: #0C4579;
  }

  &__text {
    margin-left: 100px;
    font-size: global.rem(16);
    font-weight: normal;
    line-height: calc(24 / 16);
  }
}

@include global.media(sp) {

  .p-fv {

    &__title-wrapper {
      margin-right: auto;
      margin-left: auto;
    }

    &__title {
      font-size: global.rem(30);
    }

    &__text {
      margin-right: auto;
      margin-left: auto;
      font-size: global.rem(16);
    }
  }
}
