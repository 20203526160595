@use "../../global" as global;

.p-company-map {

  &__wrapper {
    max-width: 700px;
    margin: 0 auto;
  }

  &__iframe {
    width: 100%;
    aspect-ratio: calc(5 / 3.8);
  }
}
