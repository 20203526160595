@charset "UTF-8";

// ==========================================================================
// Variables
// ==========================================================================

// import
@import "https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap";

// font
$font-base: "Noto Sans JP",sans-serif;
$baseFontSize: 16;

// width
$width-base: 1360px;
$width-inner: 1120px;
$width-service-detail: 600px;

// color
$color-white: #FFFFFF;
$color-black: #333333;

// common
$color-bg-light-gray: #F9F9FB;
$color-bg-gray: #EEEFF0;
$color-bg-purple: #8C6D8C;
$color-font-black: #2B303D;
$color-border-blue: #00478B;
$color-company-link-bg-white: #FDFCFC;
$color-event-status-blue: #042D59;

// button
$color-button-bg-blue: #042D59;
$color-button-bg-blue-font: #F9F9FB;
$color-button-bg-blue-border: #F9F9FB;
$color-button-bg-white: #F9F9FB;
$color-button-bg-white-font: #00478B;
$color-button-bg-white-border: #042D59;
$color-button-bg-orange: #FC9203;

// circle-button
$color-circle-button-bg-blue: #00478B;
$color-circle-button-bg-white: #F9F9FB;

// top-fv
$color-top-fv-bg-blue: #1C3148;
$color-top-fv-bg-gray: #F9F9FB;
$color-top-fv-font-blue: #042D59;

// header
$color-header-bg: #FFFFFF;
$color-header-active: #292F3D;

// footer
$color-footer-bg-gray: #F9F9FB;

// slider-pager
$color-pager-bg-gray: #B7BBBF;
$color-pager-active-bg-gray: #7A8189;

// top climate change
$color-category-label-gray: #3F4E62;
$color-title-bg-gray: rgba(63, 78, 98, 50%);

// top recruitment
$color-top-recruitment-bg-blue: #22324A;

// top contact
$color-top-contact-bg-blue: #22324A;

// sub fv
$color-sub-fv-bg-blue: #042D59;

// features
$color-number-bg-blue: #2B303D;
$color-features-bg-gray: #F9F9FB;
$color-features-border-gray: #D9D9D9;

// features output
$color-card-bg-gray: #E8E8E8;

// post
$color-category-font-gray: #B7BBBF;
$color-title-bg-light-gray: rgba(255, 255, 255, 80%);

// search
$color-search-border-gray: #B7BBBF;
$color-search-submit-bg-gray: #BBBCCA;

// posts
$color-date-gray: #4B4B4B;

// consulting
$color-arrow-gray: #CBC8C8;

// column popular
$color-column-popular-bg: #F0F0F0;

// height
$height-header-pc: 77px;
$height-header-sp: 62px;

// section
$padding-y-section-pc: 60px;
$padding-x-section: 15px;
$width-min: 350px;

// z-index
$layer: (
  modal : 100,
  header : 20,
  tooltip : 10,
  default : 1);

// breakpoint
$sp-breakpoints: 767;
$tb-breakpoints: 1023;
$tb-l-breakpoints: 1240;
$sp-breakpoints-px: $sp-breakpoints * 1px;
$tb-breakpoints-px: $tb-breakpoints * 1px;
$tb-l-breakpoints-px: $tb-l-breakpoints * 1px;
$breakpoints: (
  sp: "only screen and (max-width: #{$sp-breakpoints-px})",
  tb: "only screen and (max-width: #{$tb-breakpoints-px})",
  tb-l: "only screen and (max-width: #{$tb-l-breakpoints-px})",
);
