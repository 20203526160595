@use "../../global" as global;

.p-service-detail {

  &__heading-container {
    padding-top: 60px;
  }

  &__category {
    @include global.font-settings(12, 12, 1.6, 1.6, 500, 50);

    margin-bottom: 1rem;
    color: global.$color-category-label-gray;
  }

  &__title {
    @include global.font-settings(32, 24, 2.4, 2.4, 600, 50);

    margin-bottom: 2rem;
  }

  &__excerpt {
    @include global.font-settings(14, 14, 1.4, 1.4, 400, 50);

    width: 80%;
    margin-bottom: 1.5rem;

    @include global.media(tb) {
      width: 100%;
    }
  }

  &__link-wrapper {
    display: flex;
    gap: 1.5rem;
    max-width: 80%;
    padding: 1.5rem;
    margin-bottom: 4rem;
    background-color: #F9F9F9;

    @include global.media(tb) {
      flex-direction: column;
      margin-right: auto;
      margin-left: auto;
    }

    @include global.media(sp) {
      max-width: 100%;
    }
  }

  &__link {
    @include global.font-settings(17, 17, 1.8, 1.8, 500, 50);

    width: 100%;
    height: 100%;
    padding: 5px;
    line-height: 17px;
    color: global.$color-button-bg-white-font;
    text-align: center;
    background-color: global.$color-white;
    border: solid 2px;
    border-color: global.$color-button-bg-white-border;
    border-radius: 5px;
    transition: 0.3s;

    &:hover {
      color: global.$color-button-bg-blue-font;
      background-color: global.$color-button-bg-blue;
      border-color: global.$color-button-bg-blue-border;
    }

    &--bg-blue {
      color: global.$color-button-bg-blue-font;
      background-color: global.$color-button-bg-blue;
      border-color: global.$color-button-bg-blue-border;

      &:hover {
        color: global.$color-button-bg-white-font;
        background-color: global.$color-button-bg-white;
        border-color: global.$color-button-bg-white-border;
      }
    }
  }

  &__contents,
  &__bottom-container {

    & h2 {
      @include global.font-settings(22, 22, 1.8, 1.8, 600, 50);

      margin-top: 2rem;
      margin-bottom: 0.5rem;
    }

    & p {
      @include global.font-settings(15, 15, 1.4, 1.4, 400, 50);

      margin-bottom: 0.5rem;
    }

    & figure {
      padding: 0;
      margin-bottom: 1.5rem;
      overflow: hidden;
    }

    & img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }

    & ul {
      padding: 1.5rem;
      margin-bottom: 1.5rem;
      background-color: #F1F1F1;
    }

    & li {
      @include global.font-settings(16, 16, 1.8, 1.8, 400, 50);

      margin-left: 1.5rem;
      list-style-type: disc;
    }

    & h3 {
      @include global.font-settings(20, 20, 1.8, 1.8, 600, 50);

      margin-top: 3rem;
      margin-bottom: 0.5rem;

      &::before {
        display: inline-block;
        padding: 3px 10px;
        margin-right: 10px;
        color: global.$color-white;
        background-color: global.$color-button-bg-blue;
      }

      &:nth-of-type(1)::before {
        content: "01";
      }

      &:nth-of-type(2)::before {
        content: "02";
      }

      &:nth-of-type(3)::before {
        content: "03";
      }

      &:nth-of-type(4)::before {
        content: "04";
      }

      &:nth-of-type(5)::before {
        content: "05";
      }

      &:nth-of-type(6)::before {
        content: "06";
      }

      &:nth-of-type(7)::before {
        content: "07";
      }

      &:nth-of-type(8)::before {
        content: "08";
      }

      &:nth-of-type(9)::before {
        content: "09";
      }

      &:nth-of-type(10)::before {
        content: "10";
      }
    }

    & h4 {
      @include global.font-settings(18, 18, 1.8, 1.8, 600, 50);

      padding: 0;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      border: none;
    }

    & a {
      position: relative;
      display: inline-block;
      text-decoration: underline;
      text-underline-offset: 5px;
    }

    & a.before-arrow {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      margin-left: 50px;

      &::before {
        position: absolute;
        top: 50%;
        left: -50px;
        display: inline-block;
        width: 40px;
        height: 40px;
        padding: 5px;
        margin-right: 5px;
        content: "";
        background-color: global.$color-button-bg-blue;
        background-image: url("../images/arrow-icon-white.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20px;
        border-radius: 50%;
        transition: all 0.3s;
        transform: translateY(-50%);
      }

      &:hover::before {
        transform: scale(1.1) translateY(-45%);
      }
    }
  }
}

.wp-block-columns {
  margin-bottom: 1.5rem;

  & img {
    aspect-ratio: 16 / 9;
  }
}

.service-contact {
  position: sticky;
  top: 0;
  width: 320px;
  height: 750px;

  &__container {
    height: auto;
  }
}

@include global.media(tb) {

  .service-contact {
    margin: 0 auto;

    &__container {
      position: static;
      top: 0;
      right: 0;
      width: 310px;
      margin: 0 auto;
      transform: none;
    }
  }
}
