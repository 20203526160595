@use "../../global" as global;

.c-section {

  &__title-wrapper {
    margin-bottom: 40px;

    &--news {
      margin-bottom: 80px;
    }

    &--features-project {
      margin-bottom: 80px;
    }

    &--width1000 {
      max-width: 1000px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__title {
    @include global.font-settings(29, 22, false, false, 600, 50);

    text-align: center;

    &--text-align-start {
      text-align: start;
    }
  }

  &__text-wrapper {

    &--services {
      margin-bottom: -30px;
    }

    &--features {
      margin-bottom: 45px;
    }

    &--works {
      margin-bottom: 25px;
    }

    &--climate-change {
      margin-bottom: 80px;
    }

    &--event {
      margin-bottom: 40px;
    }

    &--white-papers {
      margin-bottom: 70px;
    }

    &--features-project-ex {
      margin-bottom: 70px;
    }

    &--features-output-ex {
      margin-bottom: 70px;
    }

    &--service-problem {
      margin-bottom: 50px;
    }

    &--learning-proposal {
      margin-bottom: 50px;
    }
  }

  &__text {
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;

    @include global.font-settings(17, 16, 1.6, 1.6, 400, 50);

    @include global.media(sp) {
      text-align: start;
    }
  }

  &__button-wrapper {
    width: 208px;
    height: 48px;

    &--big {
      width: 100%;
      height: 80px;

      & .c-button__text {
        font-size: global.rem(24);
      }
    }

    &--middle {
      width: 280px;
      height: 64px;

      & .c-button__text {
        font-size: global.rem(22);
      }
    }
  }
}
