@use "../../global" as global;

.article-body {

  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.3rem;
  }

  h3 {
    font-size: 1.1rem;
  }

  h1 {
    position: relative;
    padding: 0.7em;
    color: #FFFFFF;
    background: #00478B;
    border-radius: 0.3em;
  }

  h2 {
    position: relative;
    padding: 0.25em 0 0.5em 0.75em;
    color: #2B303D;
    background-color: transparent;
    border-left: 7px solid #00478B;
    border-radius: 0;
  }

  h2::after {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    content: "";
    border: none;
    border-bottom: 4px solid #D3D3D3;
  }

  h3 {
    position: relative;
    padding: 0.25em 0 0.5em 0.75em;
    border-left: 4px solid #2A2D34;
  }

  h3::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    content: "";
    border-bottom: 3px solid #E2EBF8;
  }

  h4 {
    border: none;
  }
}
