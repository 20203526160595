@use "../../global" as global;

/* ----------------------------
* SPナビゲーションボタン開閉ボタン
*---------------------------- */

.p-header-custom-sp-nav__btn {
  position: relative;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  display: none;
  align-items: center;
  justify-content: center;
  width: global.$height-header-sp;
  height: global.$height-header-pc;
  color: global.$color-white;
  background-color: global.$color-border-blue;
}

@include global.media(tb-l) {

  .p-header-custom-sp-nav__btn {
    display: flex;
  }
}

@include global.media(tb-l) {

  .p-header-custom-sp-nav__btn {
    height: global.$height-header-sp;
  }
}

/* ----------------------------
* ボタンアニメーション部分
*---------------------------- */

.p-header-custom-sp-nav__btn .p-header-custom-sp-nav__bar-frame {
  position: relative;
  display: block;
  width: 32.5px;
  height: 2px;
}

.p-header-custom-sp-nav__btn .p-header-custom-sp-nav__first-bar {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: global.$color-white;
  transition: all 0.15s linear;
}

.p-header-custom-sp-nav__btn .p-header-custom-sp-nav__second-bar {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: global.$color-white;
  transition: all 0.15s linear;
  transform: translateY(-8px) rotate(0deg);
}

.p-header-custom-sp-nav__btn .p-header-custom-sp-nav__third-bar {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: global.$color-white;
  transition: all 0.15s linear;
  transform: translateY(8px) rotate(0deg);
}

.p-header-custom-sp-nav__btn.is-active .p-header-custom-sp-nav__first-bar {
  opacity: 0;
}

.p-header-custom-sp-nav__btn.is-active .p-header-custom-sp-nav__second-bar {
  width: 100%;
  transform: translateY(0) rotate(35deg);
}

.p-header-custom-sp-nav__btn.is-active .p-header-custom-sp-nav__third-bar {
  width: 100%;
  transform: translateY(0) rotate(-35deg);
}

/* ----------------------------
* SPナビゲーションメニュー表示
*---------------------------- */

/* アニメーション前のメニューの状態 */

.p-header-custom-sp-nav {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s, visibility 0.5s;
}

/* アニメーション後のメニューの状態 */

.p-header-custom-sp-nav.is-active {
  visibility: visible;
  opacity: 1;
}

/* ----------------------------
* SPナビゲーションメニューエリア
*---------------------------- */

.p-header-custom-sp-nav {
  position: fixed;
  top: global.$height-header-pc;
  right: 0;
  z-index: -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  min-width: global.$width-min;
  height: calc(100vh - global.$height-header-pc);
  height: calc(100dvh - global.$height-header-pc);
  padding-top: global.$height-header-sp;
  color: global.$color-white;
  background-color: global.$color-border-blue;

  @include global.media(tb-l) {
    top: 0;
    height: 100vh;
    height: 100dvh;
    overflow-y: scroll;
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 15px 15px 0;
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 40px;
    border-top: global.$color-white 1px solid;

    &:last-child {
      border-bottom: global.$color-white 1px solid;
    }

    &::before {
      position: absolute;
      top: 50%;
      right: 7px;
      width: 26px;
      height: 26px;
      content: "";
      background-image: url("../images/arrow-icon-white.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      image-rendering: pixelated;
      transition: inherit;
      transform: translateY(-50%);
    }
  }

  &__link {
    @include global.font-settings(16, 16, 1.5, 1.5, 400);

    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
  }
}

.p-header-custom-sp-nav-under {

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 25px 15px 0;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
  }
}
