@use "../../global" as global;

.p-company-fv {

  &__wrapper {
    position: relative;
  }

  &__image-wrapper {
    overflow: hidden;
  }

  &__contents {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    padding: 0 15px;
    transform: translate(-50%, -50%);
  }

  &__image {
    width: 100%;
    height: 450px;
    object-fit: cover;
    object-position: center;
  }

  &__title-wrapper {
    margin-bottom: 20px;
  }

  &__title {
    color: global.$color-white;
    text-align: center;

    @include global.font-settings(44, 26, 1.5, 1.5, 500, 50);
  }

  &__text-wrapper {}

  &__text {
    color: global.$color-white;
    text-align: center;

    @include global.font-settings(20, 18, 1.5, 1.5, 500, 50);
  }
}
