@use "../../global" as global;

.p-common-flows {
  display: flex;
  flex-direction: column;
  max-width: 1100px;
  margin-top: 60px;
  margin-right: auto;
  margin-left: auto;
}

.p-common-flow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 110px;

  @include global.media(sp) {
    height: 200px;
    margin-bottom: 15px;
  }

  &__number-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    aspect-ratio: 1;
    background-color: global.$color-border-blue;
    border-radius: 50%;

    @include global.media(sp) {
      width: 50px;
    }
  }

  &__number {
    color: global.$color-white;

    @include global.font-settings(26, 22, false, false, 600, false);
  }

  &__image-wrapper {
    width: 10%;
    aspect-ratio: 1;

    @include global.media(sp) {
      width: 15%;
    }
  }

  &__desc-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;

    @include global.media(sp) {
      flex-direction: column;
      width: 55%;
    }
  }

  &__title-wrapper {
    width: 30%;
    margin-right: auto;

    @include global.media(sp) {
      width: 100%;
      margin: 0;
      margin-bottom: 10px;
    }
  }

  &__title {
    @include global.font-settings(20, 18, false, false, 500, 50);
  }

  &__text-wrapper {
    width: 60%;
    margin-left: auto;

    @include global.media(sp) {
      width: 100%;
      margin: 0;
    }
  }

  &__text {
    @include global.font-settings(16, 14, 1.6, 1.6, 400, 50);
  }
}

.p-common-flow:not(:last-of-type) {

  & .p-common-flow__number-wrapper::after {
    position: absolute;
    bottom: -90%;
    z-index: -1;
    width: 25%;
    height: 100%;
    content: "";
    background-color: global.$color-border-blue;

    @include global.media(sp) {
      bottom: -340%;
      height: 350%;
    }
  }
}
