@use "../../global" as global;

.p-sub-fv {
  height: 500px;
  padding-top: global.$height-header-pc;
  background-color: global.$color-sub-fv-bg-blue;

  @include global.media(tb) {
    height: 650px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1360px;
    height: calc(500px - global.$height-header-pc);
    padding-top: 80px;
    padding-right: 15px;
    padding-left: 15px;
    margin: 0 auto;
    color: global.$color-white;

    @include global.media(tb) {
      height: calc(650px - global.$height-header-pc);
    }
  }

  &__title-wrapper {
    margin-bottom: 40px;
  }

  &__title {
    text-align: center;

    @include global.font-settings(34, 20, 1.3, 1.3, 500, 50);
  }

  &__text-wrapper {
    margin-bottom: 40px;
  }

  &__text {
    text-align: center;

    @include global.font-settings(16, 16, 1.6, 1.6, 500, 50);

    @include global.media(sp) {
      text-align: start;
    }
  }

  &__link-wrapper {
    display: flex;
    margin-left: -4px;

    @include global.media(tb) {
      flex-direction: column;
    }
  }

  &__left-button-wrapper {
    width: 280px;
    height: 65px;
    margin-right: 40px;

    @include global.media(tb) {
      margin: 0;
      margin-bottom: 20px;
    }
  }

  &__right-button-wrapper {
    width: 280px;
    height: 65px;
  }

  &__button-wrapper {
    width: 100%;
    height: 100%;
  }
}
