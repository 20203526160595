@use "../../global" as global;

.p-common-problem {

  &__bottom-arrow-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }

  &__bottom-arrow {
    width: 0;
    height: 0;
    border-color: global.$color-border-blue transparent transparent transparent;
    border-style: solid;
    border-width: 25px 250px 0;

    @include global.media(sp) {
      border-width: 25px 150px 0;
    }
  }

  &__bottom-text-wrapper{

  }

  &__bottom-text {
    text-align: center;

    @include global.font-settings(24, 18, 1.5, 1.5, 500, false);

    @include global.media(sp) {
      text-align: start;
    }
  }
}
