@use "../../global" as global;

.p-search {
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;

  &__form {
    width: 100%;
  }

  &__forms-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 1000px;
  }

  &__input-wrapper {
    width: 100%;
    margin-right: 10px;
  }

  &__input {
    width: 100%;
    height: 40px;
    padding-left: 20px;
    border: global.$color-search-border-gray 1px solid;
    border-radius: 5px;

    @include global.font-settings(14, 14, 1.3, 1.3, 400, false);
  }

  &__submit-wrapper {
  }

  &__icon-wrapper {
    margin-right: 10px;
  }

  &__icon {
    width: 20px;
    height: 20px;
    object-fit: contain;
    object-position: center;
  }

  &__submit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 40px;
    color: global.$color-white;
    background-color: global.$color-search-submit-bg-gray;
    border-radius: 5px;
    transition: 0.3s ease-out;

    &:hover {
      background-color: global.$color-button-bg-blue;
    }
  }

  &__submit-text {
    @include global.font-settings(16, 16, 1.3, 1.3, 400, false);
  }

  &__column-popular-word-container {
    max-width: 1100px;
    margin: 0 auto;
  }

  &__title-wrapper--search-icon {
    margin-bottom: 40px;
  }

  &__title-wrapper--search-icon > h2 {
    @include global.font-settings(18, 16, false, false, 600, 50);

    position: relative;
    margin-bottom: 20px;
    transform: translateX(1.5rem);
  }

  &__title-wrapper--search-icon > h2::before {
    position: absolute;
    top: 50%;
    left: -1.5rem;
    width: 18px;
    height: 18px;
    content: "";
    background-image: url("../images/popular-search-words.png");
    background-size: contain;
    transform: translateY(-45%);
  }

  &__title-wrapper--search-icon > ul {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  &__title-wrapper--search-icon a {
    text-decoration: underline;

    @include global.font-settings(17, 16, false, false, 500, 50);
  }

  &__categories {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    margin-bottom: 20px;

    @include global.media(sp) {
      flex-direction: column;
      align-items: start;
    }
  }

  &__categories-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    width: calc(100% - 13rem);

    @include global.media(sp) {
      width: 100%;
    }
  }

  &__tags {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    margin-bottom: 40px;

    @include global.media(sp) {
      flex-direction: column;
      align-items: start;
    }
  }

  &__tags-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    width: calc(100% - 11rem);

    @include global.media(sp) {
      width: 100%;
    }
  }

  &__categories > h3,
  &__tags > h3 {
    width: 10rem;
    padding: 5px 0;
  }

  &__categories a {
    display: inline-block;
    padding: 5px 1rem;
    text-decoration: none;
    background-color: #F0F0F0;

    @include global.font-settings(16, 16, false, false, 400, 50);
  }

  &__categories-num {
    display: inline-block;
    padding: 0 0.3rem;
    margin-right: -0.3rem;
    margin-left: 0.5rem;
    text-align: center;
    background-color: global.$color-white;

    @include global.font-settings(16, 16, false, false, 400, 50);
  }

  &__tags a {
    display: inline-block;
    padding: 5px 1rem;
    text-decoration: none;
    background-color: #F0F0F0;
    border-radius: 50px;

    @include global.font-settings(16, 16, false, false, 400, 50);
  }
}
