@use "../../global" as global;

/* ----------------------------
* サービスカード
*---------------------------- */

.c-card-service {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 500px;
  height: auto;
  padding: 22px;
  background-color: global.$color-features-bg-gray;
  border: global.$color-features-border-gray solid 2.5px;
  border-radius: 2px;

  @include global.media(sp) {
    max-width: none;
  }

  &--consulting-link {
    height: 400px !important;

    & .c-card-service__title {
      padding-right: 2.5rem;

      @include global.font-settings(24, 20, 1.2, 1.2, 600, 50);
      @include global.display-lines(2);
    }

    & .c-card-service__text {
      @include global.font-settings(15, 15, 1.8, 1.8, 300, false);
      @include global.display-lines(4);

      display: inline;
      padding-bottom: 5px;
      color: global.$color-font-black;
      background-image: linear-gradient(global.$color-font-black, global.$color-font-black);
      background-repeat: no-repeat;
      background-position: bottom right;
      background-size: 0 1px;
      transition: background-size 0.3s ease-out;

      &:hover {
        background-position: bottom left;
        background-size: 100% 1px;
      }
    }
  }

  &:hover {

    & .c-circle-button__bg {
      transform: scale(1.15);
    }
  }

  &__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: 50px;
  }

  &__title-wrapper {
    padding-bottom: 30px;
    margin-bottom: 20px;
    border-bottom: solid 1px global.$color-border-blue;
  }

  &__title {
    @include global.font-settings(30, 22, false, false, 600, 50);
  }

  &__text-wrapper {
    margin-bottom: 0.2rem;
  }

  &__text {
    @include global.font-settings(18, 16, 1.5, 1.5, 300, false);
  }

  &__link-wrapper {
    position: absolute;
    top: -5px;
    right: -5px;
  }

  &__link-wrapper {
    display: flex;
    align-items: center;
  }

  &__link-text-wrapper {
    padding-top: 2px;
    margin-right: 5px;
  }

  &__circle-button-wrapper {
    width: 38px;
    height: 38px;
  }

  &__link-text {
    padding-bottom: 5px;

    @include global.font-settings(16, 16, 1.2, 1.2, 500, false);

    background-image: linear-gradient(global.$color-font-black, global.$color-font-black);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 0 1px;
    transition: background-size 0.3s ease-out;
  }
}
