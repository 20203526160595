@use "../../global" as global;

.c-label-category {
  display: inline-block;
  max-width: 60px;
  height: 18px;
  padding: global.rem(2) global.rem(7);
  overflow: hidden;
  color: global.$color-white;
  background-color: global.$color-category-label-gray;
  border-radius: 50px;

  @include global.font-settings(10, 10, 1.4, 1.4, 600, false);

  &--events {
    max-width: 80px;
    height: 24px;
    background-color: global.$color-title-bg-gray;

    @include global.font-settings(14, 14, 1.4, 1.4, 400, false);
  }
}

.c-label-event-status {
  display: inline-block;
  min-width: 55px;
  height: 18px;
  padding: global.rem(2) global.rem(7);
  overflow: hidden;
  color: global.$color-white;
  text-align: center;
  background-color: global.$color-event-status-blue;

  @include global.font-settings(11, 11, 1.4, 1.4, 700, false);
}
