@use "../../global" as global;

.p-top-news {

  &__table-wrapper {
    max-width: 1160px;
    margin: 0 auto;

    @include global.media(tb) {
      width: 100%;
    }
  }

  &__table {
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 6px;

    @include global.media(tb) {
      width: 100%;
    }
  }

  &__th {
    width: 100px;
    color: global.$color-white;
    text-align: center;
    vertical-align: middle;
    background-color: global.$color-bg-purple;

    @include global.font-settings(18, 16, 1.5, 1.5, 500, false);

    @include global.media(tb) {
      display: none;
    }
  }

  &__th-sp {
    @include global.font-settings(18, 16, 1.5, 2.5, 500, false);

    display: none;
    min-height: 40px;
    color: global.$color-white;
    text-align: center;
    vertical-align: middle;
    background-color: global.$color-bg-purple;

    @include global.media(tb) {
      display: block;
    }
  }

  &__tr {
    height: 60px;

    @include global.media(tb) {
      width: 100%;
      height: auto;
    }
  }

  &__td {
    padding-left: 1em;

    @include global.font-settings(18, 16, 1.5, 1.5, 500, false);

    vertical-align: middle;
    background-color: global.$color-bg-gray;

    @include global.media(tb) {
      width: 100%;
      height: 40px;
      padding: 1em 0.5em;
    }
  }

  &__link {

    &:hover {

      & .p-top-news__text {
        background-position: bottom left;
        background-size: 100% 1px;
      }
    }
  }

  &__text {
    display: inline;
    word-break: break-all;
    overflow-wrap: break-all;
    background-image: linear-gradient(global.$color-font-black, global.$color-font-black);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 0 1px;
    transition: background-size 0.3s ease-out;
  }
}

// 丸角テーブルの装飾

table tr:first-child th:first-child {
  border-radius: 5px 0 0 5px;
}

table tr:nth-child(2) td:last-child {
  border-radius: 0 5px 0 0;
}

table tr:last-child td:last-child {
  border-radius: 0 0 5px;
}

@include global.media(tb) {

  table tr:first-child td:last-child {
    border-radius: 5px 5px 0 0;
  }

  table tr:last-child td:last-child {
    border-radius: 0 0 5px 5px;
  }
}
