@use "../../global" as global;

/* ----------------------------
* 特徴納品物カード
*---------------------------- */

.c-card-features-output {
  position: relative;
  display: flex;
  align-items: center;

  &__image-wrapper {
    aspect-ratio: calc(16 / 9);
    overflow: hidden;
    background-color: global.$color-card-bg-gray;
  }

  &__image {
    width: 100%;
    aspect-ratio: calc(16 / 9);
    object-fit: cover;
    object-position: center;
  }

  &__info-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 15px;
  }

  &__title-wrapper {
    margin-bottom: 15px;
  }

  &__title {
    @include global.font-settings(20, 18, 1.2, 1.2, 500, 50);
  }

  &__text-wrapper {
    margin-bottom: 15px;
  }

  &__text {
    @include global.font-settings(16, 14, 1.6, 1.6, 400, false);
  }
}
