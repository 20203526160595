@use "../../global" as global;

.p-top-fv {

  &__wrapper {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__left-content {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 50vw;
    margin: 0 0 0 calc(50% - 50vw);
    background-color: global.$color-top-fv-bg-blue;

    @include global.media(tb) {
      justify-content: center;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  &__badge-link {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 180px;
    height: 180px;
    clip-path: polygon(0 0, 0% 100%, 100% 0);
    background-color: global.$color-white;
    transition: 0.3s;

    @include global.media(tb) {
      width: 140px;
      height: 140px;
    }

    &:hover {
      background-color: global.$color-button-bg-blue;

      & .p-top-fv__badge-link-text {
        color: global.$color-white;
      }

      & ~ .p-top-fv__badge-border {
        background-color: global.$color-white;
      }
    }
  }

  &__badge-border {
    position: absolute;
    top: 0;
    left: 0;
    width: 182px;
    height: 182px;
    clip-path: polygon(0 0, 0% 100%, 100% 0);
    background-color: global.$color-top-fv-bg-blue;
    transition: 0.3s;

    @include global.media(tb) {
      width: 142px;
      height: 142px;
    }
  }

  &__badge-link-text-wrapper {
    position: absolute;
  }

  &__badge-link-text {
    position: absolute;
    top: 30px;
    left: 5px;
    color: global.$color-top-fv-bg-blue;
    text-align: center;
    transition: 0.3s;
    transform: rotate(-45deg);

    @include global.media(tb) {
      top: 10px;
      left: -5px;
    }

    @include global.media(sp) {
      top: 25px;
      left: 0;
    }

    @include global.font-settings(20, 18, 1.3, 1.3, 500, false);
  }

  &__left-content-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 725px;
    padding-right: 15px;
    padding-left: 138px;

    @include global.media(tb) {
      align-items: center;
      padding-left: 0;
    }
  }

  &__title-wrapper {
    margin-bottom: 60px;
  }

  &__title {
    color: global.$color-white;

    @include global.font-settings(64, 42, 1.3, 1.3, 400, false);

    @include global.media(tb) {
      text-align: center;
    }
  }

  &__text-wrapper {
    margin-bottom: 66px;
  }

  &__text {
    color: global.$color-white;

    @include global.font-settings(13, 13, 1.6, 1.6, 500, false);

    @include global.media(tb) {
      text-align: center;
    }
  }

  &__link-wrapper {
    display: flex;
    margin-left: -4px;

    @include global.media(tb) {
      flex-direction: column;
    }
  }

  &__left-button-wrapper {
    width: 210px;
    height: 48px;
    margin-right: 25px;

    @include global.media(tb) {
      margin: 0;
      margin-bottom: 20px;
    }
  }

  &__right-button-wrapper {
    width: 210px;
    height: 48px;
  }

  &__right-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    padding-left: 10%;
    margin-top: -75px;
    background-color: global.$color-top-fv-bg-gray;

    @include global.media(tb) {
      display: none;
    }
  }

  &__work-title-wrapper {
    margin-bottom: 30px;
  }

  &__work-title {
    color: global.$color-top-fv-font-blue;

    @include global.font-settings(20, 20, false, false, 600, false);

    & > span {
      font-size: global.rem(48);
      font-weight: 500;
    }
  }

  &__work-list {
    margin-bottom: 46px;
    margin-left: 5px;
    list-style-position: inside;
    list-style-type: disc;
  }

  &__work-item {
    margin-bottom: 20px;
    color: global.$color-top-fv-font-blue;
    text-indent: -1.7rem;

    @include global.font-settings(18, 18, false, false, 500, false);
  }

  &__work-link {
    position: relative;

    &::before {
      position: absolute;
      top: 50%;
      right: 0;
      z-index: 100;
      width: global.rem(18);
      height: global.rem(18);
      content: "";
      background-image: url("../images/arrow-icon-blue.png");
      background-size: contain;
      transform: translateY(-50%);
    }

    &::after {
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 100%;
      height: 1px;
      content: "";
      background: global.$color-font-black;
      transition: transform 0.2s;
      transform: scale(0, 1);
      transform-origin: right top;
    }

    &:hover::after {
      transform: scale(1, 1);
      transform-origin: left top;
    }

    @include global.font-settings(16, 16, 1.2, 1.2, 500, false);
  }

  &__image-wrapper {
    width: 390px;
    padding-left: 15px;
  }
}
