@use "../../global" as global;

/* ----------------------------
* 研修提案カード
*---------------------------- */

.c-card-learning-proposal {
  display: flex;
  align-items: center;
  height: 280px;
  overflow: hidden;
  border: global.$color-border-blue 3px solid;
  border-radius: 5px;

  &__title-content {
    width: 100%;
    padding: 22px;
    background-color: global.$color-border-blue;
  }

  &__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__title-wrapper {
    margin-bottom: 20px;
  }

  &__title {
    color: global.$color-white;
    text-align: center;

    @include global.font-settings(30, 24, false, false, 600, 50);
  }

  &__sub-title-wrapper {
    margin-bottom: 20px;
  }

  &__sub-title {
    color: global.$color-white;
    text-align: center;

    @include global.font-settings(18, 16, false, false, 500, 50);
  }

  &__desc-content {
    padding: 1.5em;
    background-color: global.$color-white;
  }

  &__text-wrapper {
  }

  &__text {
    @include global.font-settings(16, 14, 1.6, 1.6, 400, 50);
  }

  &__link-wrapper {
    position: absolute;
    right: -5px;
    bottom: -5px;
  }

  &__link-wrapper {
    display: flex;
    align-items: center;
  }

  &__link-text-wrapper {
    padding-top: 2px;
    margin-right: 5px;
  }

  &__circle-button-wrapper {
    width: 38px;
    height: 38px;
  }

  &__link-text {
    padding-bottom: 5px;

    @include global.font-settings(16, 16, 1.2, 1.2, 500, false);

    background-image: linear-gradient(global.$color-font-black, global.$color-font-black);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 0 1px;
    transition: background-size 0.3s ease-out;
  }
}
