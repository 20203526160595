@use "../../global" as global;

.p-common-recruitment {
  display: flex;
  height: 390px;

  &:hover {

    & .c-circle-button__bg {
      transform: scale(1.15);
    }

    & .p-common-recruitment__link-text {
      background-position: bottom left;
      background-size: 100% 1px;
    }
  }

  &__left-content {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-top: 120px;
    padding-left: 50px;
    background-color: global.$color-top-recruitment-bg-blue;

    @include global.media(tb) {
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 0;
    }
  }

  &__title-wrapper {
    display: flex;
    align-items: flex-start;
    margin-bottom: 85px;
  }

  &__title-image-wrapper {
    width: 270px;
    margin-right: 20px;

    @include global.media(tb) {
      width: 200px;
      margin: 10px;
    }
  }

  &__heading-wrapper {
    padding-top: 3px;

    @include global.media(tb) {
      padding-top: 8px;
    }
  }

  &__heading {
    color: global.$color-white;

    @include global.font-settings(36, 26, 1.2, 1.2, 500, false);
  }

  &__text {
    color: global.$color-white;

    @include global.font-settings(22, 16, 1.2, 1.2, 500, false);
  }

  &__button-sp-wrapper {
    display: none;

    @include global.media(tb) {
      display: block;
      width: 100%;
      margin: 40px 0;
    }
  }

  &__right-content {
    position: relative;
    width: 50%;

    @include global.media(tb) {
      display: none;
    }
  }

  &__image-wrapper {
    right: calc(50% - 50vw);
    width: 50vw;
    height: 390px;
    overflow: hidden;
  }

  &__image {
    width: 50vw;
    height: 390px;
    object-fit: cover;
    object-position: center;
  }

  &__link-wrapper {
    position: absolute;
    right: 20px;
    bottom: 40px;
    display: flex;
    align-items: center;
  }

  &__link-text-wrapper {
    padding-top: 2px;
    margin-right: 10px;
  }

  &__link-text {
    display: inline;
    color: global.$color-white;
    background-image: linear-gradient(global.$color-white, global.$color-white);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 0 1px;
    transition: background-size 0.3s ease-out;

    @include global.font-settings(22, 22, 1.2, 1.2, 500, false);
  }

  &__circle-button-wrapper {
    width: 50px;
    height: 50px;
  }
}
