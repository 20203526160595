@use "../../global" as global;

.p-consulting-proposal {
  display: flex;
  justify-content: center;
  width: 100%;

  &__image-wrapper {
    max-width: 1000px;

    @include global.media(sp) {
      width: 100%;
      margin-bottom: 20px;
      overflow-x: scroll;
    }
  }

  &__image {
    @include global.media(sp) {
      width: 800px;
      max-width: none;
    }
  }
}
