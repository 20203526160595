@use "../../global" as global;

.p-footer-custom {
  display: flex;
  padding-left: 45px;
  margin-right: auto;

  @include global.media(tb) {
    flex-direction: column;
  }
}

.p-footer-custom-logo {
  width: 320px;
  margin-bottom: 45px;

  @include global.media(tb) {
    width: 250px;
  }

  &__image-wrapper {
    margin-bottom: 8px;
  }

  &__company-name-wrapper {
    padding-left: 5px;
  }

  &__company-name {
    color: global.$color-white;

    @include global.font-settings(22, 18, false, false, 700, 50);
  }
}

.p-footer-custom-nav {
  width: 100%;
  margin-left: 150px;

  &__list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 75%;
    height: 250px;
    padding-bottom: 80px;
    padding-left: 10px;
  }

  &__item {
    width: 40%;
    margin-bottom: 15px;
  }

  &__link {
    position: relative;
    color: global.$color-white;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      content: "";
      background: global.$color-white;
      transition: transform 0.2s;
      transform: scale(0, 1);
      transform-origin: right top;
    }

    &:hover::after {
      transform: scale(1, 1);
      transform-origin: left top;
    }

    @include global.font-settings(16, 16, false, false, 400, false);
  }
}

@include global.media(tb) {

  .p-footer-custom-nav {
    margin-left: 0;

    &__list {
      flex-wrap: nowrap;
      width: auto;
      height: auto;
    }

    &__item {
      width: 100%;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 50px;
      }
    }
  }
}
