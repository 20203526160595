@use "../../global" as global;

.p-company-advisor {

  &__wrapper {
    display: flex;
    justify-content: center;
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;

    @include global.media(sp) {
      flex-direction: column;
    }
  }

  &__contents {
    display: flex;
    flex-direction: column;
    width: 70%;
    padding: 0 15px;

    @include global.media(sp) {
      width: 100%;
      padding: 15px 0;
    }
  }

  &__image-wrapper {
    width: 15%;
    height: 100%;
    margin-right: 40px;
    overflow: hidden;

    @include global.media(sp) {
      width: 80%;
      margin: 0 auto;
    }
  }

  &__image {
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center;
  }

  &__title-wrapper {
    margin-bottom: 20px;
  }

  &__title {
    @include global.font-settings(24, 20, 1.4, 1.4, 500, 50);
  }

  &__text-wrapper {}

  &__text {
    @include global.font-settings(16, 14, 1.6, 1.6, 400, false);
  }
}
