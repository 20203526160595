@use "../../global" as global;

.p-section {

  &__title-wrapper {
    margin-bottom: 40px;
  }

  &__title {
    font-family: global.$font-base;
    font-size: global.rem(42);
    font-weight: 100;
  }
}
