@use "../../global" as global;

/* ----------------------------
* swiper共通
*---------------------------- */

.swiper {
  height: auto;
}

.swiper-wrapper {
  width: 100%;
  height: auto;
  padding-top: 0;
}

.swiper-slide {
  height: auto;
}

.swiper-pagination-bullets {
  width: 100px;
}

.swiper-pagination-bullet {
  width: 12px !important;
  height: 12px !important;
  margin: 0 0 0 10px !important;
  background: #FFFFFF;
}

.swiper-pagination-bullet:first-child {
  margin: 0 !important;
}

.swiper-button-prev,
.swiper-button-next {
  width: 50px;
  height: 50px;
  background-color: global.$color-black;
  border-radius: 50%;
}

.disabled {
  cursor: default;
  opacity: 0;
}

@include global.media(sp) {

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
}

/* ----------------------------
* トップカルーセル
*---------------------------- */

.swiper.swiper-climate-change {

  & .swiper-wrapper {
    position: relative;
    width: 100%;
    margin-bottom: 30px;
  }

  & .swiper-slide {
    width: auto !important;
  }

  & .swiper-button-prev,
  & .swiper-button-next {
    width: 50px;
    height: 50px;
    background-color: global.$color-black;
    border-radius: 50%;
  }

  & .swiper-button-next {
    position: absolute;
    top: 105px;
    right: 75px;
  }

  & .swiper-button-prev {
    position: absolute;
    top: 105px;
    left: 75px;
  }
}

.swiper-pagination-white {
  width: 100% !important;

  & .swiper-pagination-bullet {
    width: 25px !important;
    height: 3px !important;
    background-color: global.$color-category-label-gray;
    border-radius: 2px !important;
  }
}
