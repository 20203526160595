@use "../../global" as global;

.p-header-custom-logo {
  display: flex;
  align-items: center;
  padding-left: 42px;
  margin-right: auto;

  &__image-wrapper {
    width: 180px;
  }

  &__image {

    &.is-active {
      display: none;
    }
  }

  &__image-sp-menu {
    display: none;

    &.is-active {
      display: block;
    }
  }
}

.p-header-custom-nav {
  display: flex;

  &__list {
    display: flex;
    align-items: center;
  }

  &__item {
    margin-right: 15px;

    &:last-child {
      margin-right: 20px;
    }
  }

  &__link {
    position: relative;
    display: inline-block;
    height: 100%;
    line-height: 77px;
    text-decoration: none;

    @include global.font-settings(13, 13, false, false, 500, false);
    @include global.hover-effect-menu(global.$color-header-active, 5px);
  }

  @include global.media(tb-l) {
    display: none;
  }
}

.p-header-custom-right-nav {
  display: flex;
  padding-right: 40px;

  &__list {
    display: flex;
    align-items: center;
  }

  &__item {
    margin-right: 15px;

    &:last-child {
      margin-right: 20px;
    }
  }

  &__link {
    position: relative;
    display: inline-block;
    display: flex;
    align-items: center;
    height: 100%;
    line-height: 77px;
    text-decoration: none;

    @include global.font-settings(13, 13, false, false, 500, false);

    &:hover {

      & .p-header-custom-right-nav__text-wrapper {
        color: global.$color-button-bg-orange;
        background-color: global.$color-white;
        border: 1px solid global.$color-button-bg-orange;
      }
    }
  }

  &__text-wrapper {
    display: flex;
    align-items: center;
    height: 35px;
    padding: 0 15px;
    color: global.$color-white;
    background-color: global.$color-button-bg-orange;
    border: 1px solid global.$color-button-bg-orange;
    transition: all 0.3s;
  }

  &__icon {
    width: 25px;
    height: 25px;
    padding-right: 3px;
  }

  @include global.media(tb-l) {
    display: none;
  }
}
