@use "../../global" as global;

/* ----------------------------
* 課題カード
*---------------------------- */

.c-card-common-problem {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  border: solid 1px global.$color-border-blue;
  border-radius: 50%;

  &__image-wrapper {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
    overflow: hidden;
  }

  &__image {
    object-fit: contain;
  }

  &__title-wrapper {
    width: 60%;
    margin-bottom: 10px;
  }

  &__title {
    @include global.font-settings(16, 16, false, false, 600, false);

    text-align: center;
  }

  &__text-wrapper {
    width: 60%;
  }

  &__text {
    @include global.font-settings(14, 12, 1.6, 1.6, 400, 50);
  }
}
