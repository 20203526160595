@use "../../global" as global;

.p-features-project {
  display: flex;
  gap: 4%;
  max-width: 1100px;
  margin: 0 auto;

  @include global.media(sp) {
    flex-direction: column;
  }

  &__list-wrapper {
    width: 48%;

    @include global.media(sp) {
      width: 100%;
    }
  }

  &__list {
    margin-left: 1.5rem;
    list-style: outside;
  }

  &__item {
    @include global.font-settings(20, 18, 1.8, 1.8, 500, 50);
  }

  &__image-wrapper {
    width: 48%;

    @include global.media(sp) {
      width: 100%;
    }
  }

  &__image {
    width: 100%;
    object-fit: contain;
  }
}
