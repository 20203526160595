@use "../global" as global;

.l-header-custom {
  @include global.z-index(header);

  position: fixed;
  width: 100%;
  height: global.$height-header-pc;
  background-color: global.$color-header-bg;

  @include global.media(tb-l) {
    height: global.$height-header-sp;
  }

  &__inner {
    @include global.inner(global.$width-base);

    display: flex;
    height: 100%;
  }
}
