@use "../../global" as global;

.p-features-project-ex {

  &__wrapper {
    margin: 0 auto;
  }

  &__image-wrapper {
    max-width: 1200px;
    margin: 0 auto;
  }

  &__image {
    width: 100%;
  }
}
