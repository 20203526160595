@use "../../global" as global;

.c-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  color: global.$color-button-bg-white-font;
  background-color: global.$color-button-bg-white;
  border: solid 2px;
  border-color: global.$color-button-bg-white-border;
  border-radius: 5px;
  transition: 0.3s;

  &__text {
    width: calc(100% - 10px);
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: global.rem(16);
    font-weight: 500;
    text-align: center;
  }

  &__icon,
  &__icon-hover {
    position: absolute;
    top: 50%;
    right: 8px;
    width: 26px;
    height: 26px;
    transition: inherit;
    transition: 0.3s ease-out;
    transform: translateY(-50%);
  }

  & .c-button__icon-hover {
    display: none;
  }

  &:hover {
    color: global.$color-button-bg-blue-font;
    background-color: global.$color-button-bg-blue;
    border-color: global.$color-button-bg-blue-border;

    & .c-button__icon {
      right: 5px;
    }

    & .c-button__icon-hover {
      right: 5px;
      display: block;
    }
  }

  &--bg-blue {
    color: global.$color-button-bg-blue-font;
    background-color: global.$color-button-bg-blue;
    border-color: global.$color-button-bg-blue-border;

    &:hover {
      color: global.$color-button-bg-white-font;
      background-color: global.$color-button-bg-white;
      border-color: global.$color-button-bg-white-border;
    }
  }

  &--big {

    & .c-button__icon,
    & .c-button__icon-hover {
      right: 20px;
    }

    &:hover {

      & .c-button__icon,
      & .c-button__icon-hover {
        right: 17px;
      }
    }
  }

  &--bg-orange {
    color: global.$color-white;
    background-color: global.$color-button-bg-orange;
    border-color: global.$color-button-bg-orange;
  }
}

.c-circle-button {
  position: relative;

  &__bg {
    width: 38px;
    height: 38px;
    padding: 0 5px;
    background-color: global.$color-circle-button-bg-blue;
    border-radius: 50px;
    transition: 0.3s;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 55%;
    width: 20px;
    height: 20px;
    transform: translate(-50%, -50%);
  }

  &--bg-white {

    & > .c-circle-button__bg {
      width: 60px;
      height: 60px;
      background-color: global.$color-circle-button-bg-white;
    }
  }
}

.c-card-features-service {

  & .c-circle-button__icon {
    object-position: -2px 0;
  }
}
