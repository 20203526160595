@use "../../global" as global;

/* ----------------------------
* ホワイトペーパーカード
*---------------------------- */

.c-card-top-white-paper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: global.$color-white;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 25%);

  &:hover {

    & .c-card-top-white-paper__image {
      transform: scale(1.1);
    }

    & .c-card-top-white-paper__title {
      background-position: bottom left;
      background-size: 100% 1px;
    }
  }

  &__image-wrapper {
    aspect-ratio: calc(16 / 9);
    overflow: hidden;
  }

  &__image {
    transition: 0.3s ease-out;
  }

  &__info-wrapper {
    display: flex;
    width: 100%;
    height: 90px;
    padding: 15px;
  }

  &__title-wrapper {

  }

  &__title {
    display: inline;
    background-image: linear-gradient(global.$color-font-black, global.$color-font-black);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 0 1px;
    transition: background-size 0.3s ease-out;

    @include global.font-settings(16, 16, 1.5, 1.5, 600, 50);
  }
}
