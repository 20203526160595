@use "../../global" as global;

.p-features-reason {
  max-width: 1100px;
  margin: 0 auto;

  &__wrapper {
    display: flex;
    gap: 4%;
    justify-content: center;
    margin-bottom: 90px;

    &:last-child {
      margin-bottom: 0;
    }

    &--even {
      flex-direction: row-reverse;
    }

    @include global.media(tb) {
      flex-direction: column-reverse;
    }
  }

  &__desc {
    display: flex;
    flex-direction: column;
    width: 48%;
    margin-right: auto;

    &--even {
      margin-right: 0;
      margin-left: auto;
    }

    @include global.media(tb) {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
    }
  }

  &__title-content {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__number-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin-right: 20px;
    background-color: global.$color-number-bg-blue;
    border-radius: 10px;

    @include global.media(sp) {
      width: 45px;
      height: 45px;
      margin-right: 10px;
      border-radius: 5px;
    }
  }

  &__number {
    color: global.$color-white;

    @include global.font-settings(24, 20, 1.3, 1.3, 500, false);
  }

  &__title {
    @include global.font-settings(22, 18, 1.3, 1.3, 500, 50);
  }

  &__text-content {
    @include global.media(sp) {
      padding: 0;
    }
  }

  &__text {
    @include global.font-settings(20, 14, 1.8, 1.8, 500, 100);
  }

  &__image-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48%;
    margin-left: auto;

    &--even {
      margin-right: auto;
      margin-left: 0;
    }

    @include global.media(tb) {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
    }
  }

  &__image-wrapper {
    @include global.media(sp) {
      margin-bottom: 15px;
    }
  }

  &__image {
    object-fit: contain;
  }
}
