@use "../../global" as global;

/* ----------------------------
* 特徴カード
*---------------------------- */

.c-card-top-feature {
  position: relative;
  display: flex;
  align-items: center;

  &__image-wrapper {
    width: 150px;
    margin-bottom: 20px;
  }

  &__title-wrapper {
    margin-bottom: 10px;
  }

  &__title {
    @include global.font-settings(20, 18, false, false, 500, 50);

    text-align: center;
  }

  &__text {
    @include global.font-settings(14, 14, 1.5, 1.5, 300, 50);
  }
}
