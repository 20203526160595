@use "../../global" as global;

.p-company-info {

  &__table-wrapper {
    max-width: 940px;
    margin: 0 auto;
  }

  &__table {
    width: 100%;
    border: global.$color-black 2px solid;
  }

  &__tr {
    height: 70px;
    border: global.$color-black 2px solid;
  }

  &__th {
    width: 20%;
    text-align: center;

    @include global.font-settings(16, 14, 1.3, 1.3, 400, false);

    vertical-align: middle;
    border: global.$color-black 2px solid;
  }

  &__td {
    width: 80%;
    padding-left: 2em;
    vertical-align: middle;

    @include global.font-settings(16, 14, 1.3, 1.3, 400, false);

    @include global.media(sp) {
      padding: 0.5em;
    }
  }

  &__imgs {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
  }

  &__img-wrapper {
    width: 50px;
    margin: 0.5rem 0;
    text-align: center;
  }

  &__img {
    width: 100%;
    max-width: 300px;
    height: auto;
  }
}
