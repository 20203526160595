@use "../../global" as global;

.p-top-event__cards {
  max-width: 900px;
  height: auto;
  margin: 0 auto;
  margin-bottom: 50px;
}

.c-card-top-event {
  display: flex;
  height: 198px;
  padding: 15px 20px;
  margin-bottom: 20px;
  background-color: global.$color-white;

  &:hover {

    & .c-card-top-event__image {
      transform: scale(1.1);
    }

    & .c-card-top-event__title {
      background-position: bottom left;
      background-size: 100% 1px;
    }
  }

  @include global.media(tb) {
    flex-direction: column;
    height: auto;
  }

  &__image-wrapper {
    width: 30%;
    height: auto;
    margin-right: 20px;
    overflow: hidden;

    @include global.media(tb) {
      width: 100%;
      aspect-ratio: calc(16 / 9);
      margin: 0;
      margin-bottom: 20px;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    transition: 0.3s ease-out;
    object-fit: cover;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 70%;

    @include global.media(tb) {
      width: 100%;
    }
  }

  &__status-wrapper {
    margin-bottom: 15px;
  }

  &__title-wrapper {
    margin-bottom: 5px;
  }

  &__title {
    @include global.font-settings(16, 16, 1.5, 1.5, 600, 50);

    display: inline;
    background-image: linear-gradient(global.$color-font-black, global.$color-font-black);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 0 1px;
    transition: background-size 0.3s ease-out;
  }

  &__text-wrapper {
    margin-bottom: 5px;
  }

  &__text {
    @include global.display-lines(2);
    @include global.font-settings(11, 11, 1.5, 1.5, 400, false);
  }

  &__date-wrapper{

  }

  &__date {
    @include global.display-lines(1);
    @include global.font-settings(13, 13, 1.5, 1.5, 400, false);
  }

  &__place-wrapper {

  }

  &__place {
    @include global.display-lines(1);
    @include global.font-settings(13, 13, 1.5, 1.5, 400, false);
  }
}
