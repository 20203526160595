@use "../../global" as global;

.p-footer-policies {
  width: 100%;
  padding: 0 45px 60px;

  &__content {
    display: flex;

    @include global.media(sp) {
      flex-direction: column;
    }
  }

  &__policy-wrapper {
    margin-right: 32px;

    &:last-child {
      margin-right: 0;
    }

    @include global.media(sp) {
      margin-right: 0;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__policy {
    position: relative;
    font-size: global.rem(10);
    color: global.$color-white;

    &::after {
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 1px;
      content: "";
      background: global.$color-white;
      transition: transform 0.2s;
      transform: scale(0, 1);
      transform-origin: right top;
    }

    &:hover::after {
      transform: scale(1, 1);
      transform-origin: left top;
    }
  }
}
