@use "../../global" as global;

.p-company-ceo {

  &__wrapper {
    display: flex;
    justify-content: center;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;

    @include global.media(sp) {
      flex-direction: column;
    }
  }

  &__contents {
    display: flex;
    flex-direction: column;
    width: 60%;
    padding: 0 15px;

    @include global.media(sp) {
      width: 100%;
      padding: 15px 0;
    }
  }

  &__image-wrapper {
    width: 30%;
    height: 100%;
    margin-right: 40px;
    overflow: hidden;

    @include global.media(sp) {
      width: 100%;
      margin: 0;
    }
  }

  &__image {
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center;
  }

  &__title-wrapper {
    margin-bottom: 20px;
  }

  &__title {
    @include global.font-settings(34, 24, 1.4, 1.4, 500, 50);
  }

  &__text-wrapper {
    margin-bottom: 20px;
  }

  &__text {
    @include global.font-settings(20, 18, 1.8, 1.8, 400, 100);
  }

  &__sns-wrapper{
  }

  &__twitter-wrapper {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }

  &__note-wrapper {
    display: inline-block;
    width: 30px;
    height: 30px;
  }
}
