@charset "UTF-8";

// ==========================================================================
// mixins
// ==========================================================================

@use "./variables" as global;
@use "./functions" as functions;

// Inner Setting
@mixin inner($inner-width) {
  max-width: $inner-width;
  margin-right: auto;
  margin-left: auto;
}

// Z-index Setting
@mixin z-index($key) {
  @if map-has-key(global.$layer, $key) {
    z-index: map-get(global.$layer, $key);
  } @else {
    @error "[ERR]$layer does not have #{$key}.";
  }
}

// Responsive Movie
@mixin rwd-iframe($width: 16, $height: 9) {
  position: relative;
  padding-top: percentage(calc($height / $width));

  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

// Responsive Image
@mixin rwd-image($width: 16, $height: 9) {
  position: relative;
  padding-top: percentage(calc($height / $width));

  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

// Breakpoint
@mixin media($breakpoint) {
  @if map-has-key(global.$breakpoints, $breakpoint) {
    @media #{map-get(global.$breakpoints, $breakpoint)} {
      @content;
    }
  } @else {
    @error "[ERR]$breakpoints does not have #{$breakpoint}.";
  }
}

// Link Color Setting
@mixin link-color($normal, $hover: $normal) {
  color: $normal;
  text-decoration: none;
  transition: 0.3s;

  &:hover,
  &:focus,
  &:active {
    color: lighten($hover, 40%);
    text-decoration: none;
  }
}

// hover effect menu
@mixin hover-effect-menu($color, $height) {

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: $height;
    content: "";
    background: $color;
    transition: transform 0.2s;
    transform: scale(0, 1);
    transform-origin: right top;
  }

  &:hover::after {
    transform: scale(1, 1);
    transform-origin: left top;
  }

  &.current {
    position: relative;
    display: inline-block;

    &::before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: $height;
      content: "";
      background: $color;
    }
  }
}

@mixin hover-under-line($color) {
  padding-bottom: 0.25em;
  text-decoration: none;
  background: linear-gradient($color, $color) 0 100%/0 1px no-repeat;
  transition: background 0.3s;

  &:hover {
    background-size: 100% 1px;
    transform-origin: left top;
  }
}

// 呼び出し例
// @include display-lines(表示したい行数)
@mixin display-lines($line-number) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line-number;
  overflow: hidden;
}

// 呼び出し例
// @include font-settings(PCフォントサイズ, SPフォントサイズ, PC行送り , SP行送り, 太さ, トラッキング)
@mixin font-settings($pc-size,
$sp-size: $pc-size,
$pc-height: false,
$sp-height: false,
$weight: false,
$spacing: false) {
  $sp-rems: functions.rem($sp-size);
  $pc-rems: functions.rem($pc-size);
  $recommendation: calc((100vw - global.$sp-breakpoints-px) * (($pc-size - $sp-size) / (global.$tb-breakpoints - global.$sp-breakpoints)) + $sp-rems);

  font-smoothing: subpixel-antialiased;
  font-size: clamp($sp-rems, $recommendation, $pc-rems) !important;

  @if $pc-height {
    line-height: $pc-height !important;
  }

  @if $sp-height {
    @include media(sp) {
      line-height: $sp-height !important;
    }
  }

  @if $weight {
    font-weight: $weight !important;
  }

  @if $spacing {
    letter-spacing: calc($spacing / 1000) * 1em !important;
  }
}

// 呼び出し例
// @include cards(カラム数, カード間の横幅 , カード間の縦幅, SP時のカラム数, SP時カード間の横幅, カード間の縦幅)
@mixin cards($col_num,
$margin-x,
$margin-y,
$col_num_sp: 1,
$margin-x-sp: false,
$margin-y-sp: false) {
  box-sizing: border-box;

  @if $col_num_sp == 1 {
    $margin-x-sp: $margin-x;
  } @else {
    @if $margin-x-sp {
      $margin-x-sp: $margin-x-sp;
    } @else {
      $margin-x-sp: $margin-x;
    }

    @if $margin-y-sp {
      $margin-y-sp: $margin-y-sp;
    } @else {
      $margin-y-sp: $margin-y;
    }
  }

  & > .p-cards__item {
    flex-direction: column;

    // カラム数、カード間マージンから、カードの幅を算出
    $column_width: percentage(calc(100 / $col_num) * 0.01);

    // 小数点第二位以下を切り捨て
    $set_width: calc(#{calc(floor($column_width * 100) / 100)} - #{$margin-x} * calc(#{$col_num - 1} / #{$col_num}));

    width: $set_width;
    height: auto;
    margin-right: $margin-x;
    margin-bottom: $margin-y;

    // 右端のカードのマージンを0に設定
    &:nth-of-type(#{$col_num}n) {
      margin-right: 0;
    }

    @include media(sp) {
      // カラム数、カード間マージンから、カードの幅を算出
      $column_width: percentage(calc(100 / $col_num_sp) * 0.01);

      // 小数点第二位以下を切り捨て
      $set_width: calc(#{calc(floor($column_width * 100) / 100)} - #{$margin-x-sp} * calc(#{$col_num_sp - 1} / #{$col_num_sp}));

      // @if $col_num_sp == 1 {
      //   $set_width: 100%;
      // }

      width: $set_width;
      margin-right: $margin-x-sp;
      margin-bottom: $margin-y-sp;

      &:nth-of-type(#{$col_num}n) {
        margin-right: $margin-x-sp;
      }

      &:nth-of-type(#{$col_num_sp}n) {
        margin-right: 0;
      }
    }
  }
}
