@use "../../global" as global;

.p-common-contact {
  display: flex;
  color: global.$color-white;

  @include global.media(tb) {
    flex-direction: column;
  }

  &__left-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    height: 420px;
    padding-right: 40px;
    padding-left: 105px;
    border-right: 4px solid global.$color-white;

    @include global.media(tb) {
      width: 100%;
      padding: 0;
      border-right: none;
      border-bottom: 4px solid global.$color-white;
    }
  }

  &__right-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    height: 420px;
    padding-right: 85px;
    padding-left: 60px;

    @include global.media(tb) {
      width: 100%;
      padding: 0;
    }
  }

  &__title-wrapper {
    margin-bottom: 40px;
  }

  &__title {
    @include global.font-settings(42, 32, 1.2, 1.2, 500, 50);
  }

  &__text-wrapper {
    margin-bottom: 40px;
  }

  &__text {
    @include global.font-settings(20, 16, 1.5, 1.5, 500, 50);
  }

  &__button-wrapper {
    height: 80px;
  }
}
