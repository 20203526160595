@use "../../global" as global;

.p-security-policy {

  &__chapter {
    margin-bottom: 120px;
  }

  &__section {

  }

  &__item {

  }

  &__h2-wrapper {
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 1px solid #E6E6E6;
  }

  &__h2 {
    font-size: global.rem(28);
    line-height: 1.75;
  }

  &__h3-wrapper {
    margin: 64px 0 40px;
  }

  &__h3 {
    font-size: global.rem(22);
    line-height: 1.75;
  }

  &__h4-wrapper{

  }

  &__h4 {
    font-size: global.rem(18);
    line-height: 1.7;
  }

  &__text-wrapper{

  }

  &__text {
    margin-bottom: 1rem;
    font-size: global.rem(18);
    line-height: 1.7;

    &:last-child {
      margin-bottom: 0;
    }

    &--sign {
      text-align: end;
    }
  }

  &__order-list {
    padding-left: 1.5rem;
    list-style-type: decimal;
  }

  &__list-item {
    padding-bottom: 1.5rem;
    padding-left: 0.5rem;
  }

  &__table {
    width: 100%;
  }

  &__tbody{

  }

  &__sp-header {
    display: none;

    @include global.media(sp) {
      display: inline;
      font-weight: 600;
      color: global.$color-top-fv-bg-blue;
    }
  }

  &__tr {
    height: 30px;
    border-top: solid 1px global.$color-black;

    &:first-of-type {
      color: global.$color-white;
      background-color: global.$color-top-fv-bg-blue;

      & > th {
        background-color: global.$color-top-fv-bg-blue;
      }

      & > th:first-of-type {
        border-radius: 0;
      }

      @include global.media(sp) {
        display: none;
      }
    }

    &:last-of-type {
      border-bottom: solid 1px global.$color-black;
    }

    & > td:nth-of-type(1) {
      white-space: nowrap;

      @include global.media(sp) {
        padding-top: 20px;
      }
    }

    & > td:nth-of-type(2) {
      white-space: nowrap;
    }

    & > td:last-of-type {
      @include global.media(sp) {
        padding-bottom: 20px;
      }
    }
  }

  &__th {
    text-align: center;

    @include global.media(sp) {
      display: none;
    }
  }

  &__td {
    padding: 5px 10px;

    @include global.media(sp) {
      display: block;
    }
  }

  &__table--middle {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 30px;

    @include global.media(sp) {
      width: 100%;
    }

    & td {
      text-align: center;

      @include global.media(sp) {
        display: table-cell;
      }
    }

    & tr:first-of-type {
      @include global.media(sp) {
        display: table-row;
      }
    }

    & th {
      @include global.media(sp) {
        display: table-cell;
      }
    }

    & tr > td:nth-of-type(1) {
      @include global.media(sp) {
        padding-top: 5px;
      }
    }

    & tr > td:last-of-type {
      @include global.media(sp) {
        padding-bottom: 5px;
      }
    }
  }
}
