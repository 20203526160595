@use "../global" as global;

/* ----------------------------
* 共通
*---------------------------- */

.l-main {
  min-width: global.$width-min;

  &--bg-white {
    background-color: global.$color-white;
  }
}

.l-common-services {

  &__inner {
    @include global.inner(global.$width-base);

    max-width: 1100px;
    padding: 120px 0;

    @include global.media(tb) {
      padding-right: global.$padding-x-section;
      padding-left: global.$padding-x-section;
    }
  }
}

.l-pager {

  &__inner {
    padding: 0 0 60px;

    @include global.inner(global.$width-base);

    @include global.media(tb) {
      padding-right: global.$padding-x-section;
      padding-left: global.$padding-x-section;
    }
  }
}

.l-common-recruitment {
  margin: 60px 0;
  overflow: hidden;
  background-color: global.$color-top-recruitment-bg-blue;

  &__inner {
    @include global.inner(global.$width-base);

    @include global.media(tb) {
      padding-right: global.$padding-x-section;
      padding-left: global.$padding-x-section;
    }
  }
}

.l-common-contact {
  background-color: global.$color-top-contact-bg-blue;

  &__inner {
    padding: 30px 0;

    @include global.inner(global.$width-base);

    @include global.media(tb) {
      padding-right: global.$padding-x-section;
      padding-left: global.$padding-x-section;
    }
  }
}

.l-section-button {
  display: flex;
  flex-direction: column;
  align-items: center;

  &--small-char {

    .c-button__text {
      font-size: global.rem(14);
      letter-spacing: calc(50 / 1000) * -1em !important;
    }
  }
}

.l-common-works {

  &__inner {
    padding: global.$padding-y-section-pc 0;

    @include global.inner(global.$width-base);

    @include global.media(tb) {
      padding-right: global.$padding-x-section;
      padding-left: global.$padding-x-section;
    }
  }
}

.l-content {
  padding-top: 77px !important;
}

.l-common-download {
  background-color: global.$color-white;

  &__inner {
    padding: global.$padding-y-section-pc global.$padding-x-section;

    @include global.inner(global.$width-base);

    @include global.media(tb) {
      padding-right: global.$padding-x-section;
      padding-left: global.$padding-x-section;
    }
  }
}
