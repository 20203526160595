@use "../../global" as global;

.p-service-detail-qa {

  &__list {
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
  }

  &__container {
    padding: 2rem 0;
    border-bottom: 1px solid global.$color-bg-gray;

    &:last-of-type {
      border-bottom: none;
    }
  }

  &__q-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  &__q-icon-wrapper {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-right: 0.5rem;
  }

  &__q-icon {
    @include global.font-settings(18, 16, 1, 1, 500, 50);

    width: inherit;
    height: 100%;
    line-height: 38px !important;
    color: global.$color-white;
    text-align: center;
    background-color: global.$color-number-bg-blue;
    border-radius: 50px;

    @include global.media(sp) {
      line-height: 38px !important;
    }
  }

  &__q-text-wrapper {
    display: inline-block;
  }

  &__q-text {
    @include global.font-settings(18, 16, 1, 1, 400, 50);
  }

  &__a-wrapper {
    display: flex;
    align-items: center;
  }

  &__a-icon-wrapper {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-right: 0.5rem;
  }

  &__a-icon {
    @include global.font-settings(18, 16, 1, 1, 500, 50);

    width: inherit;
    height: 100%;
    line-height: 38px !important;
    text-align: center;
    border: solid 1px global.$color-number-bg-blue;
    border-radius: 50px;

    @include global.media(sp) {
      line-height: 38px !important;
    }
  }

  &__a-text-wrapper {
    display: inline-block;
  }

  &__a-text {

  }
}
